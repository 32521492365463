import axios, { AxiosInstance } from 'axios';
import objectHash from 'object-hash';

import { store } from '../../store';
import { isBedBoardIntakeForm } from '../helpers';
import { getCachedToken } from '../auth/slice';

export const Axios: AxiosInstance = axios.create();

interface Failure {
    api: string;
    count: number;
}

let failures: Failure[] = [];

const cancelledDueToNewRequestMessage = 'Cancelled due to new request';

// Object to store ongoing requests cancel tokens
const pendingRequests = new Map<string, AbortController>();

const getRequestKey = (config): string | null => {
    const dataObj = {
        url: config.url,
        method: config.method,
        data: config.data,
    };

    const isPermissionsRequest = dataObj.url.includes('permissions');
    if (!isPermissionsRequest) {
        // Cancelling the permissions requests if multiple come in cause a longer loading screen
        // Therefore we do not want to cancel these, we can let them process as they come in
        return null;
    }

    // Generate an identifier for each request
    // https://www.npmjs.com/package/object-hash#hashvalue-options
    const requestIdentifier = objectHash(dataObj, {
        ignoreUnknown: true,
    });

    return requestIdentifier;
};

Axios.interceptors.request.use(
    async (config) => {
        const state = store.getState();
        const account = state.auth?.account;
        const url = new URL(config.url);

        if (!isBedBoardIntakeForm() && account && (url.hostname === 'localhost' || url.hostname === '127.0.0.1' || url.hostname.endsWith('medonehp.com'))) {
            const token = await store.dispatch(getCachedToken());

            config.headers['Authorization'] = `Bearer ${token}`;
        }

        // Generate an identifier for each request
        const requestIdentifier = getRequestKey(config);
        if (requestIdentifier) {
            // Check if there is already a pending request with the same identifier
            if (pendingRequests.has(requestIdentifier)) {
                const sourceController = pendingRequests.get(requestIdentifier);

                // Cancel the previous request
                sourceController.abort(cancelledDueToNewRequestMessage);
            }

            // Create a new CancelToken
            const newController = new AbortController();

            config.signal = newController.signal;

            // Store the new cancel token source in the map
            pendingRequests.set(requestIdentifier, newController);
        }

        return config;
    },

    (error) => {
        if (error.toString().includes(cancelledDueToNewRequestMessage)) {
            return Promise.resolve();
        }

        Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    (response) => {
        const index = failures.findIndex((x) => x.api === response.config.url);

        if (index >= 0) {
            failures.splice(index, 1);
        }

        // Remove completed request from pending map
        const requestIdentifier = getRequestKey(response.config);
        if (requestIdentifier) {
            pendingRequests.delete(requestIdentifier);
        }

        return response;
    },

    async (error) => {
        // Remove failed request from pending map
        if (error?.config) {
            const requestIdentifier = getRequestKey(error.config);
            if (requestIdentifier) {
                pendingRequests.delete(requestIdentifier);
            }
        }

        // Don't show cancel token results here
        if (
            error?.message != null &&
            (error.message.includes('Operation canceled') || error.message.includes('CanceledError') || error.message.includes(cancelledDueToNewRequestMessage))
        ) {
            return Promise.resolve();
        }

        if (error?.config?.url === '/health') {
            return Promise.reject(error);
        }

        if (error?.response != null) {
            if (error.response?.status === 401 || error.response?.status === 403) {
                const index = failures.findIndex((x) => x.api === error.config.url);

                if (index >= 0) {
                    if (failures[index].count > 3) {
                        window.location.href = '/noaccess';

                        return Promise.resolve();
                    } else {
                        const newFailures = [...failures];

                        newFailures.splice(index, 1);
                        newFailures.push({ ...failures[index], count: failures[index].count + 1 });

                        failures = newFailures;
                    }
                } else {
                    failures.push({ api: error.config.url, count: 1 });
                }

                const token = await store.dispatch(getCachedToken());

                error.config.headers['Authorization'] = `Bearer ${token}`;

                return Axios.request(error.config);
            }

            // Don't show concurrency errors to the user
            try {
                if (
                    error.response.data != null &&
                    error.response?.status === 500 &&
                    typeof error.response?.data === 'string' &&
                    error.response?.data?.includes('DbUpdateConcurrencyException')
                ) {
                    const isNoteSaveRequest = error?.config?.url?.toLowerCase().includes('api/notes/post');
                    if (isNoteSaveRequest) {
                        return Promise.resolve();
                    }

                    return Promise.reject(`The data you're trying to save has changed since you loaded it. You may have to reload or try again.`);
                }
            } catch {}
        }

        return Promise.reject(error);
    }
);
