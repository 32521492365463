import { Popconfirm, Button, Space, Tooltip } from 'antd';
import { EnumSelectListDto, QuickNoteAcknowledgementDto, QuickNoteDto, Specialties } from '@medone/medonehp-api-client';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { handleError } from '../../../../shared/common/HandleErrors';
import { formatDateTimeLocal, isInRole } from '../../../../shared/common/helpers';

import primaryIcon from '../../../../shared/images/specialty_primary.svg';
import pmrIcon from '../../../../shared/images/specialty_pmr.svg';
import woundIcon from '../../../../shared/images/specialty_wound.svg';
import { selectQuickNoteTypes, selectSpecialties } from '../../../../shared/common/data/slice';
import { SpecialtyRole } from '../../../../shared/common/auth/RoleAuth';
import { AuthState } from '../../../../shared/common/auth/models';
import { selectAuth } from '../../../../shared/common/auth/slice';
import { signQuickNote } from '../census/slice.notes';

type Props = {
    quicknoteDto: QuickNoteDto;
    facilityId?: number;
    patientIntakeId?: number;
    onSignedCallback?: () => void;
};

const specialtyRoles = [SpecialtyRole.Primary, SpecialtyRole.PMR, SpecialtyRole.Wound];

const QuickNoteSignButtons = ({ quicknoteDto, facilityId, patientIntakeId, onSignedCallback }: Props) => {
    const dispatch = useAppDispatch();
    const allSpecialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const auth = useAppSelector<AuthState>(selectAuth);
    const quickNoteTypes = useAppSelector<EnumSelectListDto[]>(selectQuickNoteTypes);

    const handleAcknowledge = async (ack: QuickNoteAcknowledgementDto) => {
        try {
            const result = await dispatch(signQuickNote(ack.id, facilityId, patientIntakeId));

            if (result) {
                toast.success('Successfully acknowledged quick note!');

                if (onSignedCallback) {
                    onSignedCallback();
                }
            }
        } catch (errors) {
            handleError(errors, () => true);
        }
    };

    const getSpecialtyIcon = (specialty: Specialties) => {
        const dto = allSpecialties.find((x) => x.id === specialty);

        switch (specialty) {
            case Specialties.Primary:
                return <img src={primaryIcon} alt={dto?.name} className="specialty-icon" />;

            case Specialties.PMR:
                return <img src={pmrIcon} alt={dto?.name} className="specialty-icon" />;

            case Specialties.WoundCare:
                return <img src={woundIcon} alt={dto?.name} className="specialty-icon" />;

            default:
                return null;
        }
    };

    const getSpecialtyButton = (ack: QuickNoteAcknowledgementDto) => {
        if (ack.acknowledgedByUserId) {
            return (
                <Tooltip key={ack.id} title={`Signed by ${ack.acknowledgedByUserName} on ${formatDateTimeLocal(ack.acknowledgedTimestamp)}`}>
                    <Button type="ghost" style={{ backgroundColor: 'limegreen' }}>
                        {getSpecialtyIcon(ack.specialty)}
                    </Button>
                </Tooltip>
            );
        } else if (isInRole(auth?.permissions, [specialtyRoles[ack.specialty - 1]])) {
            return (
                <Popconfirm key={ack.id} placement="top" title="Acknowledge note?" onConfirm={() => handleAcknowledge(ack)} okText="Yes" cancelText="No">
                    <Button type="ghost">{getSpecialtyIcon(ack.specialty)}</Button>
                </Popconfirm>
            );
        } else {
            return (
                <Tooltip key={ack.id} title="Awaiting Acknowledgment">
                    <Button type="ghost">{getSpecialtyIcon(ack.specialty)}</Button>
                </Tooltip>
            );
        }
    };

    return (
        quicknoteDto?.acknowledgements && quicknoteDto.acknowledgements.length > 0
            ? <Space align="center">
                {quicknoteDto.acknowledgements.map((ack) => {
                    return getSpecialtyButton(ack);
                })}
            </Space>
            : <Space align="center">
                <>No acknowlegement necessary for {quickNoteTypes.find(x => x.id === quicknoteDto.quickNoteType).name}</>
            </Space>
    );
};

export default QuickNoteSignButtons;
