import { useState } from 'react';
import { Form, Button, Modal, Radio, Alert, RadioChangeEvent } from 'antd';
import { CensusDto, EnumSelectListDto, DischargeDto, VisitDto, NoteTypes } from '@medone/medonehp-api-client';
import moment from 'moment';
import { AccountInfo } from '@azure/msal-browser';
import { useHistory } from 'react-router-dom';

import { dischargePatient, selectCurrentCensus, toggleDischargeModal, selectDischargeModalVisible, getOrCreateNote } from '../../slice.notes';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { selectPatientDispositions } from '../../../../../../shared/common/data/slice';
import { isInRole } from '../../../../../../shared/common/helpers';

import CustomDatePicker from '../../../../../../shared/common/components/CustomDatePicker';
import { handleError } from '../../../../../../shared/common/HandleErrors';
import { postAdHocVisit } from '../../../schedule/slice.schedules';
import { selectAccount, selectPermissions } from '../../../../../../shared/common/auth/slice';
import { Role } from '../../../../../../shared/common/auth/RoleAuth';
import CustomSelect, { getCustomSelectListOptions } from '../../../../../../shared/common/components/CustomSelect';
import { PostAcutePaths } from '../../../index.routes';

type Props = {
    hideConfirmations?: boolean;
    useVisit?: VisitDto;
    onDischargeCompleted?: (result: boolean, didYouSeeThePatient: boolean) => void;
};

const DischargeModal = ({ hideConfirmations = false, useVisit = null, onDischargeCompleted = null }: Props) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [showNoOptions, setNoOptions] = useState<boolean>(false);
    const auth = useAppSelector<AccountInfo>(selectAccount);
    const permissions = useAppSelector<string[]>(selectPermissions);
    const census = useAppSelector<CensusDto>(selectCurrentCensus);
    const dispositions = useAppSelector<EnumSelectListDto[]>(selectPatientDispositions);
    const isVisible = useAppSelector<boolean>(selectDischargeModalVisible);
    const [showNonBillableAlert, setShowNonBillableAlert] = useState<boolean>(false);

    const hideDidYouSeeThePatient = () => {
        return hideConfirmations || isInRole(permissions, [Role.CLINICAL_COORDINATOR, Role.SCRIBE]);
    };

    const hideCreateDischargeSummary = () => {
        return !showNoOptions || hideConfirmations || isInRole(permissions, [Role.CLINICAL_COORDINATOR]);
    };

    const showModal = async (visible: boolean) => {
        await dispatch(toggleDischargeModal(visible));
    };

    const handleSeenPatient = async (e: RadioChangeEvent) => {
        const { value } = e.target;

        setNoOptions(!value);
    };

    const handleDoDischargeSummary = async (e: RadioChangeEvent) => {
        const { value } = e.target;

        setShowNonBillableAlert(value);
    };

    const handleSubmit = async () => {
        setLoading(true);

        const values = await form.validateFields();
        const { didYouSeeThePatient, createDischargeSummary, patientDisposition, dischargeDate } = values;

        const dischargeDto = {
            patientIntakeId: census.patientIntakeId,
            patientDisposition,
            dischargeDate: moment.utc(dischargeDate), // Moment was making it 5:00AM, for instance, in EDT which was causing validation errors on the server
        } as DischargeDto;

        const dischargeResult = await dispatch(dischargePatient(dischargeDto));

        if (dischargeResult) {
            try {
                if (didYouSeeThePatient || createDischargeSummary) {
                    const patchedValues =
                        useVisit === null
                            ? ({
                                  facilityId: census.admittedToId,
                                  facilityType: census.facilityType,
                                  patientIntakeId: census.patientIntakeId,
                                  providerId: auth?.localAccountId,
                                  noteType: NoteTypes.DischargeSummary,
                                  dischargeBillable: didYouSeeThePatient,
                              } as VisitDto)
                            : ({
                                  ...useVisit,
                                  noteType: NoteTypes.DischargeSummary,
                                  dischargeBillable: didYouSeeThePatient,
                              } as VisitDto);

                    const visit = await dispatch(postAdHocVisit(patchedValues));

                    if (visit != null) {
                        // If coming from the census...
                        if (!window.location.pathname.includes('note/edit')) {
                            const result = await dispatch(getOrCreateNote(visit, census));
                            if (result != null) {
                                await dispatch(toggleDischargeModal(false, visit, census));

                                form.resetFields();

                                setNoOptions(false);
                                showModal(false);
                                setLoading(false);

                                // Store page coming from, so we can redirect them back after the note sign/close
                                localStorage.setItem('note-redirect-to', window.location.pathname);

                                // Navigate to note edit URL
                                history.push(`${PostAcutePaths.Notes.Edit}/${result.id}`);
                            }
                        } else {
                            await dispatch(toggleDischargeModal(false, visit, census));

                            form.resetFields();

                            setNoOptions(false);
                            showModal(false);
                            setLoading(false);
                        }
                    }
                } else {
                    form.resetFields();

                    setNoOptions(false);
                    showModal(false);
                    setLoading(false);
                }

                if (onDischargeCompleted != null) {
                    onDischargeCompleted(dischargeResult, didYouSeeThePatient);
                }
            } catch (ex) {
                handleError(ex, () => true);
            }
        } else {
            showModal(false);
            setLoading(false);
        }
    };

    return (
        <Modal
            title={useVisit === null ? 'Create Discharge' : 'Discharge'}
            open={isVisible}
            footer={null}
            onCancel={() => showModal(false)}
            className="discharge-modal"
            zIndex={1002}
            destroyOnClose
        >
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
                <Form.Item name="dischargeDate" label="Discharge Date" rules={[{ required: true, message: 'Discharge Date is required' }]}>
                    <CustomDatePicker max={moment().format('YYYY-MM-DD')} />
                </Form.Item>

                <Form.Item name="patientDisposition" label="Discharge Location" rules={[{ required: true, message: 'Discharge Location is required' }]}>
                    <CustomSelect aria-label="Location" options={getCustomSelectListOptions(dispositions, 'name')} listHeight={400} />
                </Form.Item>

                <Form.Item
                    name="didYouSeeThePatient"
                    label="Did you see the patient at discharge?"
                    rules={[{ required: !hideDidYouSeeThePatient(), message: 'Did you see the patient at discharge is required' }]}
                    hidden={hideDidYouSeeThePatient()}
                >
                    <Radio.Group onChange={handleSeenPatient}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>

                {!useVisit && (
                    <Form.Item
                        name="createDischargeSummary"
                        label="Do you want to do a Discharge Summary?"
                        rules={[{ required: !hideCreateDischargeSummary(), message: 'Do you want to do a Discharge Summary is required.' }]}
                        hidden={hideCreateDischargeSummary()}
                    >
                        <Radio.Group onChange={handleDoDischargeSummary}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                )}

                {showNonBillableAlert && (
                    <>
                        <Alert showIcon type="warning" message="The Discharge Summary will be marked as non-billable." />
                        <br />
                    </>
                )}

                <Button loading={isLoading} type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form>
        </Modal>
    );
};

export default DischargeModal;
