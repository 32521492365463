import { useState, useEffect, useRef } from 'react';
import { Button, Row, Form, Input, Select, Col, Checkbox, Modal, Descriptions, Spin, Divider } from 'antd';
import { toast } from 'react-toastify';
import { CensusDto, EnumSelectListDto, FacilityDto, PatientIntakeDto, PatientIntakeSpecialtyDto, Specialties, UndoDischargeDto } from '@medone/medonehp-api-client';
import moment from 'moment';

import { selectCreateIntakeOnDischarge, updatePatientIntake } from '../../slice.patient-intakes';
import {
    filterOptions,
    filterOptionsStartsWith,
    formatDateTime,
    getEnumDisplayValue,
    getSelectListOptions,
    renderPatientIntakeFullName,
} from '../../../../../../shared/common/helpers';
import { selectArrivingFroms } from '../../../admin/slice.arriving-froms';
import { selectActiveFacilities } from '../../../admin/slice.facilities';
import { selectPatientTypes } from '../../../admin/slice.patient-types';
import { selectFacilityTypes, selectGenders, selectPatientDispositions, selectSpecialties } from '../../../../../../shared/common/data/slice';
import { useAppSelector, useAppDispatch } from '../../../../../../shared/hooks';
import { handleError } from '../../../../../../shared/common/HandleErrors';
import { selectCurrentCensus, selectDischargeModalVisible, toggleDischargeModal } from '../../slice.notes';
import { fetchCensusByPatientIntakeId, readmitPatient, setCreateIntakeOnDischarge } from '../../slice';

import CustomSelect, { getCustomSelectListOptions, getEnumCustomSelectListOptions } from '../../../../../../shared/common/components/CustomSelect';
import CustomDatePicker from '../../../../../../shared/common/components/CustomDatePicker';
import PatientIntakeQuickNote from './PatientIntakeQuickNote';

type Params = {
    patientIntake?: PatientIntakeDto;
    onFinishCallback?: () => void;
};

const buttonStyles = { height: 'auto', marginBottom: '15px' };

const PatientIntakeForm = ({ patientIntake, onFinishCallback }: Params) => {
    const dispatch = useAppDispatch();
    const facilityTypes = useAppSelector<EnumSelectListDto[]>(selectFacilityTypes);
    const specialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const patientTypes = useAppSelector<EnumSelectListDto[]>(selectPatientTypes);
    const facilities = useAppSelector<FacilityDto[]>(selectActiveFacilities);
    const arrivingFroms = useAppSelector<EnumSelectListDto[]>(selectArrivingFroms);
    const patientDispositions = useAppSelector<EnumSelectListDto[]>(selectPatientDispositions);
    const genders = useAppSelector<EnumSelectListDto[]>(selectGenders);
    const dischargeModalVisible = useAppSelector<boolean>(selectDischargeModalVisible);
    const currentCensus = useAppSelector<CensusDto>(selectCurrentCensus);
    const createIntakeOnDischarge = useAppSelector<PatientIntakeDto>(selectCreateIntakeOnDischarge);
    const [selectedFacilityTypes, setSelectedFacilityTypes] = useState<any[]>(null);
    const [selectedSpecialties, setSelectedSpecialties] = useState<any[]>([]);
    const [isArriveFromOtherRequired, setArriveFromOtherRequired] = useState<boolean>(false);
    const [isDuplicate, setDuplicate] = useState<boolean>(false);
    const [duplicateMessage, setDuplicateMessage] = useState<string>(null);
    const [canDischarge, setCanDischarge] = useState<boolean>(false);
    const [canDoReturnFromED, setCanDoReturnFromED] = useState<boolean>(false);
    const [canCreateNewWithoutUndoingDischarge, setCanCreateNewWithoutUndoingDischarge] = useState<boolean>(false);
    const [canUpdateExistingDischargeDateAndCreateNew, setCanUpdateExistingDischargeDateAndCreateNew] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const [duplicateProcessing, setDuplicateProcessing] = useState<boolean>(false);
    const [isQuickNoteAdded, setIsQuickNoteAdded] = useState<boolean>(false);
    const isFinishing = useRef<boolean>(false);
    const [form] = Form.useForm();

    const handleFacilityChange = (value) => {
        const facility = facilities.find((x) => x.id === value);

        if (facility != null) {
            // Facility Types
            const facilityTypeOptions = facility.facilityTypes.map((type) => facilityTypes.find((x) => x.id === type));
            const selectedFacilityType = form.getFieldValue('facilityType');
            const selectedFacilityTypeOption = facilityTypeOptions.find((x) => x.id === selectedFacilityType);

            // Specialties
            const specialtiesOptions = facility.specialties.map((type) => specialties.find((x) => x.id === type));
            const selectedSpecialty = form.getFieldValue('facilityType');
            const selectedSpecialtyOption = specialtiesOptions.find((x) => x.id === selectedSpecialty);

            setSelectedFacilityTypes(getEnumCustomSelectListOptions(facilityTypeOptions));
            setSelectedSpecialties(getEnumCustomSelectListOptions(specialtiesOptions));

            if (!selectedFacilityTypeOption) {
                form.setFieldsValue({ facilityType: null });
            }

            if (!selectedSpecialtyOption) {
                form.setFieldsValue({ specialties: [] });
            }
        } else {
            setSelectedFacilityTypes(null);
            setSelectedSpecialties(null);

            form.setFieldsValue({ facilityType: null, specialties: [] });
        }
    };

    const handleFinishForm = async (ignoreRecentDischargesOnCreate = false) => {
        if (isFinishing.current) {
            return;
        }

        isFinishing.current = true;

        setProcessing(true);
        setDuplicate(false);
        setDuplicateMessage(null);
        setCanDischarge(false);
        setCanDoReturnFromED(false);
        setCanCreateNewWithoutUndoingDischarge(false);
        setCanUpdateExistingDischargeDateAndCreateNew(false);

        try {
            const values = await form.validateFields();

            // Map the number values to a dto so the API can accept the payload
            // We only need the specialty on the dto here, as everything else will be assumed from
            // the intake values.
            values.specialties = values.specialties.map((x) =>
                PatientIntakeSpecialtyDto.fromJS({
                    id: 0,
                    patientIntakeId: 0,
                    specialty: x as Specialties,
                    startDate: moment(),
                    endDate: null,
                    dischargedFromPrimary: false,
                    isNew: false,
                })
            );

            const patchedValues = {
                ...patientIntake,
                ...values,
                dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                patientTypeIds: values.patientTypeIds ?? [],
                ignoreRecentDischargesOnCreate: ignoreRecentDischargesOnCreate,
            } as PatientIntakeDto;

            // Only send the newQuickNote payload if it's visible
            if (!isQuickNoteAdded) {
                patchedValues.newQuickNote = null;
            }

            const result = await dispatch(updatePatientIntake(patchedValues, true));

            if (result) {
                toast.success(`Patient Intake for '${renderPatientIntakeFullName(patchedValues)}' was ${patientIntake == null ? 'created' : 'updated'}!`);

                form.resetFields();

                if (onFinishCallback != null) {
                    onFinishCallback();
                }
            }
        } catch (errors) {
            // The validator returns the message in the PatientId validation
            if (errors?.result?.errors && errors?.result?.errors['PatientId']) {
                // This field should be included by the validator
                const intakeId = parseInt(errors?.result?.errors['Id'][0]);

                if (!isNaN(intakeId)) {
                    // The discharge modal needs the census to function so we load it up here
                    await dispatch(fetchCensusByPatientIntakeId(intakeId, false));

                    const message = errors.result.errors['PatientId'];

                    setDuplicate(true);
                    setDuplicateMessage(message);

                    if (message.includes('An active patient intake was found.')) {
                        setCanDischarge(true);
                    } else if (message.includes('A patient intake was found to be discharged and is eligible for Return From ED.')) {
                        setCanDoReturnFromED(true);
                    } else if (message.includes('A patient intake was found to be recently discharged.')) {
                        // Undo discharge is an option as well
                        setCanCreateNewWithoutUndoingDischarge(true);
                    } else if (message.includes('A patient intake was found that was active on the admission date you provided.')) {
                        // Undo discharge is an option as well
                        setCanUpdateExistingDischargeDateAndCreateNew(true);
                    } else if (message.includes('A patient intake exists with a more recent admission date.')) {
                        // Cannot create a new intake
                    }
                } else {
                    handleError(errors, () => true);
                }
            } else {
                handleError(errors, () => true);
            }
        }

        isFinishing.current = false;

        setProcessing(false);
        setDuplicateProcessing(false);
    };

    const handleArrivingFromChange = (e, selectedOption) => {
        setArriveFromOtherRequired(selectedOption.label === 'Other');
    };

    const handleDiscard = async () => {
        setDuplicate(false);
        setDuplicateProcessing(false);

        dispatch(setCreateIntakeOnDischarge(null));

        form.resetFields();

        if (onFinishCallback != null) {
            onFinishCallback();
        }
    };

    const handleDischargeExisting = async () => {
        setDuplicateProcessing(true);

        // Prepare the discharge modal for auto-admit workflow
        dispatch(setCreateIntakeOnDischarge(PatientIntakeDto.fromJS(form.getFieldsValue())));

        await dispatch(toggleDischargeModal(true));
    };

    const handleReturnFromED = async () => {
        setDuplicateProcessing(true);

        const dto = {
            patientIntakeId: currentCensus.patientIntakeId,
            visitId: 0,
            canReturnFromED: true,
        } as UndoDischargeDto;

        await dispatch(readmitPatient(dto));

        form.resetFields();

        if (onFinishCallback != null) {
            onFinishCallback();
        }
    };

    const handleUndoDischarge = async () => {
        setDuplicateProcessing(true);

        const dto = {
            patientIntakeId: currentCensus.patientIntakeId,
            visitId: 0,
            canReturnFromED: false,
        } as UndoDischargeDto;

        await dispatch(readmitPatient(dto));

        form.resetFields();

        if (onFinishCallback != null) {
            onFinishCallback();
        }
    };

    const handleCreateNewIntakeDespiteRecentDischarge = async () => {
        setDuplicateProcessing(true);

        handleFinishForm(true);
    };

    const renderDischargeDate = (dischargeDate) => {
        return dischargeDate ? formatDateTime(currentCensus.dischargeDate, '', 'L') : 'Active';
    };

    const handleRemoveQuickNote = () => {
        setIsQuickNoteAdded(false);
    };

    const handleAddQuickNote = () => {
        setIsQuickNoteAdded(true);
    };

    useEffect(() => {
        return () => form.resetFields();
    }, [form, dispatch, patientIntake]);

    useEffect(() => {
        // After the disharge modal goes away and the note type gets set by the discharge workflow completing
        // then we re-call handle finish form to create the current intake
        if (createIntakeOnDischarge != null && !dischargeModalVisible) {
            handleFinishForm()
                .then(() => dispatch(setCreateIntakeOnDischarge(null)))
                .catch((err) => handleError(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dischargeModalVisible, createIntakeOnDischarge]);

    return (
        <>
            <Modal title="This patient already has an intake on this date" open={isDuplicate} onCancel={handleDiscard} footer={null} confirmLoading={duplicateProcessing}>
                <Spin spinning={duplicateProcessing}>
                    <h4 className="mb-2">Please follow the steps below to ensure overlapping intakes are avoided and the discharge history for the patient is accurate.</h4>

                    {currentCensus && (
                        <>
                            <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} className="mb-2">
                                <Descriptions.Item label="Admission Date">{formatDateTime(currentCensus.admissionDate, '', 'L')}</Descriptions.Item>
                                <Descriptions.Item label="Discharge Date">{renderDischargeDate(currentCensus.dischargeDate)}</Descriptions.Item>
                                {currentCensus.patientDisposition && (
                                    <Descriptions.Item label="Patient Disposition">{getEnumDisplayValue(patientDispositions, currentCensus.patientDisposition)}</Descriptions.Item>
                                )}
                                <Descriptions.Item label="Arriving From">{currentCensus.arrivingFrom}</Descriptions.Item>
                                <Descriptions.Item label="Room #">{currentCensus.roomNumber}</Descriptions.Item>
                            </Descriptions>

                            <h3 className="mb-2">{duplicateMessage}</h3>

                            {canDischarge && (
                                <>
                                    <Row className="mb-2">
                                        <h4>Would you like to discharge the active intake in order to start a new patient intake?</h4>
                                    </Row>

                                    <Row justify="end">
                                        <Button type="primary" block size="large" style={buttonStyles} onClick={handleDischargeExisting}>
                                            Discharge the Active Intake
                                        </Button>
                                    </Row>
                                </>
                            )}

                            {canDoReturnFromED && (
                                <>
                                    <Row className="mb-2">
                                        <h4>Would you like to reactivate the intake by going through the Return From ED process?</h4>
                                    </Row>

                                    <Row justify="end">
                                        <Button type="primary" block size="large" style={buttonStyles} onClick={handleReturnFromED}>
                                            Return From ED
                                        </Button>
                                    </Row>
                                </>
                            )}

                            {canCreateNewWithoutUndoingDischarge && (
                                <>
                                    <Row className="mb-2">
                                        <h4>Would you like to undo the discharge of the recently-discharged intake, or create a new intake?</h4>
                                    </Row>

                                    <Row justify="end">
                                        <Button type="primary" block size="large" style={buttonStyles} onClick={handleUndoDischarge}>
                                            Undo Recent Discharge
                                        </Button>
                                    </Row>

                                    <Row justify="end">
                                        <Button type="primary" block size="large" style={buttonStyles} onClick={handleCreateNewIntakeDespiteRecentDischarge}>
                                            Create New Intake Without Undoing Recent Discharge
                                        </Button>
                                    </Row>
                                </>
                            )}

                            {canUpdateExistingDischargeDateAndCreateNew && (
                                <>
                                    <Row className="mb-2">
                                        <h4>
                                            Would you like to undo the discharge of the existing intake to resume the old intake OR change the discharge date of the old intake and
                                            create a new intake?
                                        </h4>
                                    </Row>

                                    <Row justify="end">
                                        <Button type="primary" block size="large" style={buttonStyles} onClick={handleUndoDischarge}>
                                            Resume the Previous Intake
                                        </Button>
                                    </Row>

                                    <Row justify="end">
                                        <Button type="primary" block size="large" style={buttonStyles} onClick={handleDischargeExisting}>
                                            Update Existing Discharge Date and Create New Intake
                                        </Button>
                                    </Row>
                                </>
                            )}

                            <Row justify="end">
                                <Button type="ghost" block size="large" onClick={handleDiscard}>
                                    Cancel New Intake
                                </Button>
                            </Row>
                        </>
                    )}
                </Spin>
            </Modal>

            <Spin spinning={isProcessing}>
                <Form name="patient-intake-form" autoComplete="off" layout="vertical" form={form} initialValues={patientIntake} onFinish={() => handleFinishForm()}>
                    <Form.Item label="Admitted To" name="facilityId" rules={[{ required: true, message: 'Admitted To is required.' }]}>
                        <CustomSelect
                            showSearch
                            filterOption={filterOptions}
                            options={getCustomSelectListOptions(facilities, 'name')}
                            onChange={handleFacilityChange}
                            dropdownMatchSelectWidth={false}
                        />
                    </Form.Item>

                    <Row gutter={24}>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Specialties" name="specialties" rules={[{ required: true, message: 'Specialties is required.' }]}>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    disabled={selectedSpecialties == null}
                                    filterOption={filterOptionsStartsWith}
                                    options={selectedSpecialties}
                                    dropdownMatchSelectWidth={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Admission Type" name="facilityType" rules={[{ required: true, message: 'Admission Type is required.' }]}>
                                <CustomSelect disabled={selectedFacilityTypes == null} showSearch filterOption={filterOptionsStartsWith} options={selectedFacilityTypes} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Admission Date" name="admissionDate" rules={[{ required: true, message: 'Admission Date is required.' }]}>
                                <CustomDatePicker type="date" disabled={selectedFacilityTypes == null} className="w-100" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} xl={8}>
                            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'First name is required.' }]}>
                                <Input disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Middle Name" name="middleName">
                                <Input disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Last name is required.' }]}>
                                <Input disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Preferred Name" name="preferredName">
                                <Input disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                label="Date of Birth"
                                name="dateOfBirth"
                                rules={[
                                    { required: true },
                                    () => ({
                                        validator(rule, value) {
                                            if (!moment(value).isValid()) {
                                                return Promise.reject('Date of Birth is required');
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <CustomDatePicker type="masked" placeholder="MM/DD/YYYY" disabled={selectedFacilityTypes == null} className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Gender" name="gender" rules={[{ required: true, message: 'Gender is required.' }]}>
                                <CustomSelect
                                    disabled={selectedFacilityTypes == null}
                                    showSearch
                                    filterOption={filterOptionsStartsWith}
                                    options={getEnumCustomSelectListOptions(genders)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Room Number" name="roomNumber">
                                <Input disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Arriving From" name="arrivingFromLookupId" rules={[{ required: true, message: 'Arriving From is required.' }]}>
                                <CustomSelect
                                    disabled={selectedFacilityTypes == null}
                                    showSearch
                                    filterOption={filterOptions}
                                    options={getCustomSelectListOptions(arrivingFroms, 'name')}
                                    dropdownMatchSelectWidth={false}
                                    onChange={handleArrivingFromChange}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Arriving From - Other"
                                name="arrivingFromLookupOther"
                                rules={[{ required: isArriveFromOtherRequired, message: 'Arriving From - Other is required.' }]}
                                hidden={!isArriveFromOtherRequired}
                            >
                                <Input disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} xl={8}>
                            <Form.Item label="Hospital MRN" name="hospitalMRN">
                                <Input disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} xl={8}>
                            <Form.Item label="Patient Type(s)" name="patientTypeIds">
                                <Select
                                    disabled={selectedFacilityTypes == null}
                                    mode="multiple"
                                    showSearch
                                    filterOption={filterOptionsStartsWith}
                                    options={getSelectListOptions(patientTypes, 'name')}
                                    dropdownMatchSelectWidth={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={10}>
                            <Form.Item label="No Admission H&P Required" name="ignoreRequiredHpVisit" valuePropName="checked">
                                <Checkbox disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={6}>
                            <Form.Item label="Hospice Patient" name="isHospice" valuePropName="checked">
                                <Checkbox disabled={selectedFacilityTypes == null} />
                            </Form.Item>
                        </Col>
                    </Row>

                    {patientIntake == null && isQuickNoteAdded && (
                        <>
                            <Divider className="divider-quicknote-form" orientation="left" orientationMargin="12">
                                Quick Note
                            </Divider>
                            <PatientIntakeQuickNote />
                        </>
                    )}

                    <Row className="form-actions" justify="space-between">
                        <Col>
                            {isQuickNoteAdded && (
                                <>
                                    <Button type="primary" danger onClick={handleRemoveQuickNote}>
                                        Remove Quick Note
                                    </Button>
                                </>
                            )}

                            {!isQuickNoteAdded && (
                                <>
                                    <Button type="primary" onClick={handleAddQuickNote}>
                                        Add Quick Note
                                    </Button>
                                </>
                            )}
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={isProcessing}>
                                    {patientIntake == null ? 'Create Intake' : 'Update Intake'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>
    );
};

export default PatientIntakeForm;
