import { PayloadAction } from '@reduxjs/toolkit';
import {
    NotesClient,
    PrintNoteClient,
    NoteDto,
    LookupClient,
    ProblemListItemDto,
    DischargeDispositionChangeDto,
    CensusDto,
    NoteCensusDto,
    CptCodeRequestDto,
    CptCodeDto,
    CptCodeLookupClient,
    CensusClient,
    CensusGroupDto,
    NoteTypes,
    NotePrintRequestDto,
    PatientIntakesClient,
    NoteAddendumsClient,
    NoteAddendumDto,
    QuickNoteDto,
    QuickNotesClient,
    FacilityQuickNotesDto,
    ExportClient,
    NoteFaxRequestDto,
    PatientDto,
    VisitDto,
    PatientIntakeDto,
    CensusBadgeDto,
    SearchIcd10RequestDto,
    Icd10SearchResult,
    SnomedSearchResult,
    PatientLabDto,
    Icd10CodeCategories,
    QuickNoteSignDto,
    QuickNotePrintRequestDto,
} from '@medone/medonehp-api-client';
import moment, { Moment } from 'moment';
import { last } from 'lodash';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { CensusState, NoteFaxDict, CensusField, censusBadgesAdapter } from './models';
import { censusBadgeSelectors, censusSlice, fetchCensus, refreshCensus, resetVisits } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { updatePatient } from './slice.patient-overview';
import { cleanProblemList, getCleanMedicalSummaryFields } from '../../../../shared/common/helpers';
import { setCurrentUnsignedNote, setCurrentUnsignedNoteType } from '../unsigned-notes/slice';
import { fetchUnsignedStats } from '../slice';

export const reducers = {
    setNoteError: (state: CensusState, action: PayloadAction<string>) => {
        state.noteError = action.payload;
    },

    setIncompleteNotes: (state: CensusState, action: PayloadAction<NoteDto[]>) => {
        state.incompleteNotes = action.payload;
        state.errorMessage = null;
    },

    setCompletedNotes: (state: CensusState, action: PayloadAction<NoteDto[]>) => {
        state.completedNotes = action.payload;
        state.errorMessage = null;
    },

    setQuickNotes: (state: CensusState, action: PayloadAction<QuickNoteDto[]>) => {
        state.quickNotes = action.payload;
        state.errorMessage = null;
    },

    setFacilityQuickNotes: (state: CensusState, action: PayloadAction<FacilityQuickNotesDto>) => {
        state.facilityQuickNotes = action.payload;
        state.errorMessage = null;
    },

    setNote: (state: CensusState, action: PayloadAction<NoteDto>) => {
        if (action.payload != null) {
            const { signedTimestamp } = action.payload;

            state.currentNote = { ...state.currentNote, ...action.payload } as NoteDto;
            state.noteIsReadonly = signedTimestamp != null;
            state.latestHgbA1cLab = state.currentNote.latestHgbA1c;
            state.latestTshLab = state.currentNote.latestTsh;

            if (state.currentNote.problemList == null || state.currentNote.problemList.length === 0) {
                state.currentNote.problemList = [ProblemListItemDto.fromJS({})];
            } else if (state.currentNote.problemList != null) {
                const lastItem = last(state.currentNote.problemList) as ProblemListItemDto;

                // Only add a new one at the end if there isn't one yet
                if (lastItem.snomedConceptId != null) {
                    state.currentNote = { ...state.currentNote, problemList: [...state.currentNote.problemList, ProblemListItemDto.fromJS({})] } as NoteDto;
                }
            }
        } else {
            state.currentNote = null;
            state.currentNoteType = null;
            state.latestHgbA1cLab = null;
            state.latestTshLab = null;
        }

        state.errorMessage = null;
    },

    clearNote: (state: CensusState) => {
        state.currentNote = null;
        state.currentNoteType = null;
        state.latestHgbA1cLab = null;
        state.latestTshLab = null;
        state.errorMessage = null;
        state.notePrintContent = [];
        state.sideNotePrintContent = null;
        state.noteError = null;
        state.noteCensus = null;
        state.cptCodes = [];
        state.procedureCptCodes = [];
        state.noteFaxes = [];
        state.patientDocumentGroups = [];
        state.pccPatient = null;
        state.pccPatientLoading = null;
        state.currentCensus = null;
        state.patient = null;
        state.patientLabProcessing = false;
        state.completedNotes = null;
        state.incompleteNotes = null;
        state.patientNotes = null;
        state.quickNotes = null;
        state.currentPatientIntake = null;
        state.recentLabs = [];
        state.patientHospiceIntakes = [];
        state.patientDrawerOpen = false;
        state.currentStagedNote = null;
        state.currentStagedNotes = [];
        state.currentStagedNotesGrouped = [];
    },

    setLatestHgbA1c: (state: CensusState, action: PayloadAction<PatientLabDto>) => {
        state.latestHgbA1cLab = action.payload;
    },

    setLatestTsh: (state: CensusState, action: PayloadAction<PatientLabDto>) => {
        state.latestTshLab = action.payload;
    },

    setNoteCensus: (state: CensusState, action: PayloadAction<NoteCensusDto>) => {
        state.noteCensus = action.payload;
        state.noteIsReadonly = action.payload.note.signedTimestamp != null;
    },

    setNotePrint: (state: CensusState, action: PayloadAction<string[]>) => {
        state.notePrintContent = action.payload;
    },

    setSideNotePrint: (state: CensusState, action: PayloadAction<string>) => {
        state.sideNotePrintContent = action.payload;
    },

    setQuickNotePrintContent: (state: CensusState, action: PayloadAction<QuickNoteDto[]>) => {
        state.quickNotePrintContent = action.payload;
    },

    setDischargeModalVisible: (state: CensusState, action: PayloadAction<boolean>) => {
        state.dischargeModalVisible = action.payload;
    },

    setNoteIsReadOnly: (state: CensusState, action: PayloadAction<boolean>) => {
        state.noteIsReadonly = action.payload;
    },

    setCurrentCensus: (state: CensusState, action: PayloadAction<CensusDto>) => {
        state.currentCensus = action.payload;
    },

    setCurrentCensusInGroup: (state: CensusState, action: PayloadAction<CensusDto>) => {
        const newCensus = [...state.census].map((item) => {
            item.items = item.items.map((census) => {
                if (
                    census.regionId === action.payload.regionId &&
                    census.admittedTo === action.payload.admittedTo &&
                    census.arrivingFrom === action.payload.arrivingFrom &&
                    census.name === action.payload.name &&
                    census.patientIntakeId === action.payload.patientIntakeId &&
                    census.patientId == null
                ) {
                    return {
                        ...census,
                        ...action.payload,
                    };
                }

                return { ...census };
            }) as CensusDto[];

            return { ...item };
        }) as CensusGroupDto[];

        state.census = newCensus;
    },

    setCurrentNoteType: (state: CensusState, action: PayloadAction<NoteTypes>) => {
        state.currentNoteType = action.payload;

        // Set this if coming from the change note type button
        if (state.currentNote != null && action.payload != null) {
            state.currentNote = { ...state.currentNote, noteType: action.payload } as NoteDto;
        }
    },

    setCptCodes: (state: CensusState, action: PayloadAction<CptCodeDto[]>) => {
        state.cptCodes = action.payload;
    },

    setProcedureCptCodes: (state: CensusState, action: PayloadAction<CptCodeDto[]>) => {
        state.procedureCptCodes = action.payload;
    },

    setNoteFaxes: (state: CensusState, action: PayloadAction<NoteFaxDict>) => {
        const newNoteFaxes = [...state.noteFaxes, action.payload];

        state.noteFaxes = newNoteFaxes;
    },

    setUpdatedCensus: (state: CensusState, action: PayloadAction<CensusDto>) => {
        const newCensus = state.census.map((census) => {
            if (census.admittedToId === action.payload.admittedToId) {
                const index = census.items.findIndex((x) => x.patientIntakeId === action.payload.patientIntakeId);
                if (index !== -1) {
                    const items = [...census.items];

                    items.splice(index, 1);
                    items.splice(index, 0, action.payload);

                    return { ...census, items };
                }
            }

            return census;
        }) as CensusGroupDto[];

        state.census = newCensus;
    },

    setCensusField: (state: CensusState, action: PayloadAction<CensusField>) => {
        const { patientId, field, fieldValue } = action.payload;
        const badges = censusBadgeSelectors.selectAll(state.badges);

        if (badges != null) {
            const patientBadge = badges.find((x) => x.patientId === patientId);

            if (patientBadge != null) {
                const updatedBadge = { ...patientBadge, [field]: fieldValue } as CensusBadgeDto;

                censusBadgesAdapter.setOne(state.badges, updatedBadge);
            }
        }
    },

    setIsQuickNoteVisible: (state: CensusState, action: PayloadAction<number>) => {
        state.isQuickNoteVisible = action.payload;
    },
};

/**
 * It looks like CPT codes were only being loaded when the notedrawer was
 * opened from the regular path. We needed a way to load CPT codes when
 * a note was opened from the patient drawer and "view history"
 * @param note Note to load CPT codes for
 */
export function loadCptCodesForUnsign(note: NoteDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentCensus } = getState().census;
        if (currentCensus != null && currentCensus.facilityType != null && currentCensus.facilityType > 0) {
            const cptRequest = {
                facilityType: currentCensus.facilityType,
                noteType: note.noteType,
                serviceDate: moment().utc().startOf('day'),
                noteId: note.id,
            } as CptCodeRequestDto;

            await dispatch(fetchCptCodes(cptRequest));
        }
    };
}

export function fetchCptCodes(body: CptCodeRequestDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new CptCodeLookupClient(null, Axios);

        try {
            dispatch(censusSlice.actions.setCptCodes([]));
            dispatch(censusSlice.actions.setProcedureCptCodes([]));

            if (body.noteType === NoteTypes.InitialPMR || body.noteType === NoteTypes.FollowUpPMR) {
                const procedureResponse = await client.getProcedureCptCodes();

                if (procedureResponse.result.succeeded) {
                    dispatch(censusSlice.actions.setProcedureCptCodes(procedureResponse.result.entity));
                }
            }

            const response = await client.getValidCptCodes(body);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setCptCodes(response.result.entity));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function clearNoteData(): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(censusSlice.actions.clearNote());
        dispatch(setCurrentUnsignedNote(null));

        // Refresh census when closing note drawer, since we pause those during note open
        dispatch(refreshCensus());
    };
}

export function submitAddendum(addendum: NoteAddendumDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new NoteAddendumsClient(null, Axios);

        try {
            const response = await client.post(addendum);

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function submitQuickNote(quickNote: QuickNoteDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new QuickNotesClient(null, Axios);

        try {
            const response = await client.post(quickNote);

            await dispatch(fetchQuickNotesHistory(quickNote.patientId));

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function signQuickNote(ackId: number, facilityId?: number, patientIntakeId?: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new QuickNotesClient(null, Axios);

        try {
            const param = QuickNoteSignDto.fromJS({
                quickNoteAcknowledgmentId: ackId,
                facilityId: facilityId,
            });

            const response = await client.sign(param);

            if (patientIntakeId != null) {
                await dispatch(fetchQuickNotesByAdmissionId(patientIntakeId));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function fetchQuickNotes(patientId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new QuickNotesClient(null, Axios);

        try {
            const response = await client.getAllByPatientId(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setQuickNotes(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchQuickNotesHistory(patientId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new QuickNotesClient(null, Axios);

        try {
            const response = await client.getAllHistoryByPatientId(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setQuickNotes(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchQuickNotesByAdmissionId(admissionId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new CensusClient(null, Axios);

        try {
            const response = await client.getByPatientIntakeId(admissionId);

            if (response.result.succeeded) {
                await dispatch(fetchQuickNotes(response.result.entity.patientId));

                dispatch(censusSlice.actions.setUpdatedCensus(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchFacilityQuickNotes(facilityId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new QuickNotesClient(null, Axios);

        try {
            const response = await client.getAllByFacilityId(facilityId, false);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setFacilityQuickNotes(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchQuickNotePrint(ids: number[]): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new QuickNotesClient(null, Axios);

        try {
            const dto = QuickNotePrintRequestDto.fromJS({ ids: ids });
            const response = await client.print(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setQuickNotePrintContent(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function toggleDischargeModal(visible: boolean, visit?: VisitDto, census?: CensusDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentUnsignedNote } = getState().unsignedNotes;

        dispatch(censusSlice.actions.setDischargeModalVisible(visible));

        if (visit) {
            dispatch(setCurrentNoteType(visit.noteType));

            if (currentUnsignedNote != null) {
                dispatch(setCurrentUnsignedNoteType(visit.noteType));
            }
        }

        // If there is a census object coming in, set current census
        if (visible && census) {
            dispatch(censusSlice.actions.setCurrentCensus(census));
        }

        if (!visible) {
            dispatch(censusSlice.actions.setCurrentCensus(null));
        }
    };
}

export function dischargePatient(discharge: DischargeDispositionChangeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.discharge(discharge);

            if (response.result.succeeded) {
                await dispatch(refreshCensus());
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function snomedSearch(searchTerm: string, effectiveDate: moment.Moment): AppThunk<Promise<SnomedSearchResult[]>> {
    return async (dispatch: AppDispatch) => {
        const client = new LookupClient(null, Axios);

        try {
            const response = await client.searchSnomed(searchTerm, effectiveDate);

            if (response.result.succeeded) {
                return response.result.entity.results;
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function icd10Search(searchTerm: string, effectiveDate: moment.Moment, category?: Icd10CodeCategories | null): AppThunk<Promise<Icd10SearchResult[]>> {
    return async (dispatch: AppDispatch) => {
        const client = new LookupClient(null, Axios);

        try {
            const requestDto = {
                searchTerm: searchTerm,
                effectiveDate: effectiveDate,
                category: category,
            } as SearchIcd10RequestDto;

            const response = await client.searchIcd10(requestDto);

            if (response.result.succeeded) {
                return response.result.entity.results;
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function getOrCreateNote(visit: VisitDto, census: CensusDto): AppThunk<Promise<NoteDto>> {
    return async (dispatch: AppDispatch) => {
        dispatch(censusSlice.actions.clearNote());

        dispatch(
            censusSlice.actions.setPccPatientLoading({
                patientIntakeId: visit.patientIntakeId,
                loading: false,
            })
        );

        dispatch(censusSlice.actions.setPccPatient(null));

        const client = new NotesClient(null, Axios);

        try {
            if (visit == null) {
                throw Error('Visit is required.');
            }

            const response = await client.getOrCreate(visit.id, census.patientIntakeId, census.patientId);

            if (response.result.succeeded) {
                return response.result.entity;
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(null)));
        }

        return null;
    };
}

export function fetchIncompleteNotes(patientId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new NotesClient(null, Axios);

        try {
            const response = await client.getIncompleteByPatientId(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setIncompleteNotes(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchCompletedNotes(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentPatientIntake, completedNotes } = getState().census;

        // Prevent double loading note history
        if (currentPatientIntake?.patientId === patientId && completedNotes?.length > 0) {
            return;
        }

        const client = new NotesClient(null, Axios);

        try {
            const response = await client.getCompletedByPatientId(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setCompletedNotes(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchNote(noteId: number): AppThunk<Promise<NoteDto>> {
    return async (dispatch: AppDispatch) => {
        dispatch(setCurrentNote(null));

        const client = new NotesClient(null, Axios);

        try {
            const response = await client.getById(noteId);

            if (response.result.succeeded) {
                dispatch(setCurrentNote(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function fetchSideNotePrint(noteId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new PrintNoteClient(null, Axios);

        try {
            const dto = NotePrintRequestDto.fromJS({ noteId });
            const response = await client.getMarkdown(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setSideNotePrint(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchNotePrint(noteIds: number[], markdownOnly = true): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new NotesClient(null, Axios);
        const printClient = new PrintNoteClient(null, Axios);

        try {
            const noteCensusResponse = await client.getWithCensus(noteIds[0]);
            const printResponses = [];

            for (const noteId of noteIds) {
                const request = NotePrintRequestDto.fromJS({
                    noteId,
                });

                const printResponse = markdownOnly ? await printClient.getMarkdown(request) : await printClient.manualPrint(request);

                if (printResponse.result.succeeded) {
                    printResponses.push(printResponse.result.entity);
                } else {
                    handleError(printResponse, () => dispatch(censusSlice.actions.setError(null)));
                }
            }

            dispatch(censusSlice.actions.setNotePrint(printResponses));

            if (noteCensusResponse.result.succeeded) {
                dispatch(censusSlice.actions.setNoteCensus(noteCensusResponse.result.entity));
            } else {
                handleError(noteCensusResponse, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function clearNotePrint(justSideNote?: boolean): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(censusSlice.actions.setSideNotePrint(null));

        if (!justSideNote) {
            dispatch(censusSlice.actions.setNotePrint([]));
        }
    };
}

export function validateNote(note: NoteDto): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch) => {
        const client = new NotesClient(null, Axios);

        try {
            if (note) {
                note.problemList = cleanProblemList(note.problemList);
            }

            const response = await client.validate(note);

            if (response.result.succeeded) {
                dispatch(setCurrentNote(response.result.entity));
            }

            return { succeeded: response.result.succeeded, message: response.result.message };
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

/**
 * Process to update a note
 * @param note note object
 * @param sign if the note is being signed
 * @param showSpinner should the spinner be shown (allows for background updating through debounce)
 */
export function updateNote(note: NoteDto, sign = false, ignoreErrors = true, patient?: PatientDto): AppThunk<Promise<NoteDto | string>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentNote, currentPatientIntake } = getState().census;
        const client = new NotesClient(null, Axios);

        try {
            if (patient != null) {
                await dispatch(updatePatient(patient));
            }

            let response: any;

            if (sign) {
                if (note) {
                    note.problemList = cleanProblemList(note.problemList);
                }

                response = await client.sign(note);
            } else {
                response = await client.post(note);
            }

            if (response.result.succeeded) {
                const noteDto = response.result.entity as NoteDto;

                // Ensure the note coming back is still the note that's open
                // Sometimes network requests are delayed and the note is updated before the response is received
                // causing the wrong data to appear on the form
                if (noteDto.patientIntakeId === currentPatientIntake?.id && noteDto.id === currentNote?.id) {
                    dispatch(setCurrentNote(noteDto));
                }

                return response.result.entity;
            }

            if (!ignoreErrors && !response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            // Pass back error message so sign confirmation window can show it
            if (sign && !response.result.succeeded) {
                return response.result.message;
            }
        } catch (error) {
            if (!ignoreErrors) {
                handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
            }
        }

        return null;
    };
}

/**
 * Process to unsign a note
 *  - api retrieves note from db and rechecks IsUnsignable before clearing signed data
 *  - returns the note to update local store
 * @param note note object
 */
export function unSignNote(note: NoteDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new NotesClient(null, Axios);

        try {
            const response = await client.unsign(note);

            if (response.result.succeeded) {
                // When coming from the patient chart, view note
                // this will clear the note object before setting it so the useEffect that sets the note form values
                // will pull the entire NoteDto (with screenings, etc)
                dispatch(setCurrentNote(null));
                dispatch(setCurrentNote(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function deleteNote(noteId: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new NotesClient(null, Axios);

        try {
            const response = await client.delete(noteId);

            if (response.result.succeeded) {
                dispatch(clearNoteData());

                await dispatch(fetchCensus());

                dispatch(resetVisits()); // Reset the action buttons in the census table expanded rows

                // Refresh unsigned notes counts
                const { account } = getState().auth;
                if (account != null) {
                    await dispatch(fetchUnsignedStats(account.localAccountId));
                }
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(null)));
        }

        return false;
    };
}

export function strikeoutNote(noteId: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentNote, currentPatientIntake } = getState().census;
        const client = new NotesClient(null, Axios);

        try {
            const response = await client.strikeout(noteId);

            if (response.result.succeeded) {
                const noteDto = response.result.entity as NoteDto;

                // Ensure the note coming back is still the note that's open
                // Sometimes network requests are delayed and the note is updated before the response is received
                // causing the wrong data to appear on the form
                if (noteDto.patientIntakeId === currentPatientIntake?.id && noteDto.id === currentNote?.id) {
                    dispatch(setCurrentNote(noteDto));
                }
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(null)));
        }

        return false;
    };
}

export function checkPccExported(noteId: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new NotesClient(null, Axios);

        try {
            const response = await client.checkPccExported(noteId);

            if (response.result.succeeded) {
                return response.result.entity.hasBeenPccExported;
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(null)));
        }

        return false;
    };
}

export function faxNote(fax: NoteFaxRequestDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const client = new ExportClient(null, Axios);

        try {
            const response = await client.fax(fax);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(null)));
        }

        return false;
    };
}

export function fetchNoteFaxes(noteId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new ExportClient(null, Axios);

        try {
            const response = await client.getByNoteId(noteId);

            if (response.result.succeeded) {
                if (response.result.entity.length > 0) {
                    const dict = { noteId, faxes: response.result.entity } as NoteFaxDict;

                    dispatch(censusSlice.actions.setNoteFaxes(dict));
                }
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(null)));
        }
    };
}

export function takeoverNote(note: NoteDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new NotesClient(null, Axios);

        try {
            const response = await client.takeover(note);

            if (response.result.succeeded) {
                // Save note after a takeover
                await dispatch(updateNote(note, false, false));

                dispatch(setCurrentNote(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function syncLatestMedicalSummaryToCurrentNote(): AppThunk<Promise<NoteDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentNote, currentPatientIntake } = getState().census;

        try {
            if (currentNote != null && currentPatientIntake != null && currentNote.patientIntakeId === currentPatientIntake.id) {
                const msValues = getCleanMedicalSummaryFields(currentPatientIntake?.medicalSummary);

                const updatedDto: any = {
                    ...currentNote,
                    medicalSummary: msValues,
                };

                dispatch(setCurrentNote(updatedDto));

                return updatedDto;
            }
        } catch (error) {
            handleError(error, () => true);
        }

        return null;
    };
}

export const selectCompletedNotes = (state: RootState) => state.census.completedNotes;
export const selectIncompleteNotes = (state: RootState) => state.census.incompleteNotes;
export const selectQuickNotes = (state: RootState) => state.census.quickNotes;
export const selectFacilityQuickNotes = (state: RootState) => state.census.facilityQuickNotes;
export const selectNote = (state: RootState) => state.census.currentNote;
export const selectNoteCensus = (state: RootState) => state.census.noteCensus;
export const setDischargeModalVisible = (open: boolean) => censusSlice.actions.setDischargeModalVisible(open);
export const selectDischargeModalVisible = (state: RootState) => state.census.dischargeModalVisible;
export const setNoteIsReadOnly = (open: boolean) => censusSlice.actions.setNoteIsReadOnly(open);
export const selectNoteIsReadOnly = (state: RootState) => state.census.noteIsReadonly;
export const selectCurrentCensus = (state: RootState) => state.census.currentCensus;
export const selectNoteError = (state: RootState) => state.census.noteError;
export const setCurrentNoteType = (noteType: NoteTypes) => censusSlice.actions.setCurrentNoteType(noteType);
export const selectCurrentNoteType = (state: RootState) => state.census.currentNoteType;
export const setCurrentCensus = (census: CensusDto) => censusSlice.actions.setCurrentCensus(census);
export const setCurrentNote = (note: NoteDto) => censusSlice.actions.setNote(note);
export const selectCurrentNote = (state: RootState) => state.census.currentNote;
export const selectCptCodes = (state: RootState) => state.census.cptCodes;
export const selectProcedureCptCodes = (state: RootState) => state.census.procedureCptCodes;
export const selectNotePrintContent = (state: RootState) => state.census.notePrintContent;
export const selectQuickNotePrintContent = (state: RootState) => state.census.quickNotePrintContent;
export const selectNoteFaxes = (state: RootState) => state.census.noteFaxes;
export const selectSideNotePrintContent = (state: RootState) => state.census.sideNotePrintContent;
export const selectIsQuickNoteVisible = (state: RootState) => state.census.isQuickNoteVisible;
export const selectLatestHgbA1cLab = (state: RootState) => state.census.latestHgbA1cLab;
export const selectLatestTshLab = (state: RootState) => state.census.latestTshLab;

export const selectTodaysVisitCompletedNote = (state: RootState, patientIntake: PatientIntakeDto, today: Moment) => {
    const visit = state.census.visits.find((x: VisitDto) => x.patientIntakeId === patientIntake?.id && x.serviceDate.format() === today.format());
    if (visit) {
        return state.census?.completedNotes?.find((x: NoteDto) => x.visitId === visit.id);
    }

    return null;
};
